<template>
  <div class="paper" id="paper2" style="width: 30cm; height: 20.9cm; border: 1px solid black;">
    <!-- <h1 style="text-align: center; margin-left: auto; margin-right: auto;">Semaine du {{ week[Object.keys(week)[0]] }} au {{ week[Object.keys(week).at(-1)] }}</h1> -->
    <!-- <p>{{ week }}</p>
    <span>{{ weekData }}</span>
    <span>{{ total }}</span> -->
    <div class="table-container" style="display: flex; flex-direction: column; justify-content: space-evenly; align-items: center;">
      <table border="1" style="border-collapse: collapse; width: 90%; margin: 16px;">
        <thead style="background-color: rgb(201, 201, 201);">
          <tr>
            <th style="padding: 10px;">Semaine du</th>
            <th style="padding: 10px;">Espèces</th>
            <th style="padding: 10px;">Cartes Bleues</th>
            <th style="padding: 10px;">Tickets Restaurants</th>
            <th style="padding: 10px;">Nombre Tickets Restaurants</th>
            <th style="padding: 10px;">Chèques</th>
            <th style="padding: 10px;">Total CA</th>
            <th style="padding: 10px;">Coûts Marchandise</th>
            <th style="padding: 10px;">Marge Commerciale</th>
            <th style="padding: 10px;">Coefficient</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(week, index) in actualWeeksData" :key="index">
            <td style="padding: 5px;">{{ index }}</td>
            <td style="padding: 5px;">{{ Math.floor(week.total.cash * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ Math.floor(week.total.card * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ Math.floor(week.total.tickets * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ isNaN(Math.floor(week.total.nbTickets * 100) / 100) ? "&#x2205;" : Math.floor(week.total.nbTickets * 100) / 100 }}</td>
            <td style="padding: 5px;">{{ Math.floor(week.total.checks * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ Math.floor(week.total.total * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ Math.floor(week.costs * 100) / 100 }}€</td>
            <td style="padding: 5px;">{{ ((Math.floor(week.total.total * 100) / 100) - (Math.floor(week.costs * 100) / 100)).toFixed(2) }}€</td>
            <td style="padding: 5px;">{{ ((Math.floor(week.total.total * 100) / 100) / (Math.floor(week.costs * 100) / 100)).toFixed(2) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'weeklyReport',
  props: {
    startWeek: String,
    endWeek: String,
    db: Object
  },
  data () {
    return {
      weekData: {},
      weeks: [],
      total: {
        cash: 0,
        card: 0,
        tickets: 0,
        checks: 0,
        total: 0
      },
      marchandise: 0,
      actualWeeksData: {}
    }
  },
  methods: {
    exportToPDF () {
      html2pdf(document.getElementById('paper2'), {
        jsPDF: { orientation: 'landscape' }
      })
    },
    getHistoryData () {
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getHistory'
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          this.entries = data.results
          this.handleWeeks(data.results)
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Veuillez contacter votre administrateur (ton frère). Erreur : ' + err,
            showConfirmButton: false
          })
        })
    },
    handleWeeks (entries) {
      console.log('entries:', entries)
      let previousDay = 0
      let currentWeek = []
      // console.log(entries)
      const sortedEntries = Object.keys(entries).sort((a, b) => {
        const [dateANb, monthA, yearA] = a.split('/')
        const dateA = new Date(yearA, monthA - 1, dateANb).toISOString()
        const [dateBNb, monthB, yearB] = b.split('/')
        const dateB = new Date(yearB, monthB - 1, dateBNb).toISOString()
        return dateA > dateB ? 1 : -1
      })
      for (const day in sortedEntries) {
        const date = entries[sortedEntries[day]].date
        const [dateNb, month, year] = date.split('/')
        const dayNb = new Date(year, month - 1, dateNb).getDay()
        // console.log(date, dayNb, previousDay)
        if (currentWeek.length === 0) {
          // console.log('First of the week')
          currentWeek.push(date)
          previousDay = dayNb
        } else if (dayNb > previousDay) {
          // console.log('if(' + dayNb + ' > ' + previousDay + ')')
          // console.log('In the week')
          currentWeek.push(date)
          previousDay = dayNb
        } else {
          // console.log('new week')
          this.weeks.push(currentWeek)
          previousDay = 2
          currentWeek = []
          currentWeek.push(date)
        }
      }
      if (currentWeek.length > 0) {
        this.weeks.push(currentWeek)
      }
    },
    getWeeklyData () {
      const bigDayList = []
      this.weeks.forEach(week => {
        // const weekData = []
        for (let day in week) {
          day = week[day]
          bigDayList.push(day)
        }
      })

      console.log(this.weeks, bigDayList)
      // request.onsuccess = (event) => {
      //   weekData[request.result.date] = request.result
      //   // console.log(request.result)
      //   this.calculateTotal(weekData)
      // }

      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getWeeklyData',
        days: JSON.stringify(bigDayList)
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          // for (let day in this.week) {
          //   day = this.week[day]
          //   this.weekData[day] = Object.values(data.results).find(dayData => dayData.date === day)
          // }
          // this.calculateTotal()
          this.weeks.forEach(week => {
            const weekData = []
            for (let day in week) {
              day = week[day]
              weekData[day] = Object.values(data.results).find(dayData => dayData.date === day)
            }
            this.calculateTotal(weekData)
          })
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être Lus',
            html: 'Veuillez contacter votre administrateur (ton frère). Erreur : ' + err,
            showConfirmButton: false
          })
          console.error(err)
        })
    },
    calculateTotal (weekData) {
      // console.log('received total : ', weekData)
      const response = {}
      if (Object.keys(weekData).length === 0) {
        return
      }
      response.total = {}
      response.total.cash = 0
      response.total.card = 0
      response.total.tickets = 0
      response.total.nbTickets = 0
      response.total.checks = 0
      response.total.total = 0
      response.total.mavro = 0
      for (let day in Object.keys(weekData)) {
        const key = Object.keys(weekData)[day]
        day = weekData[key]
        // console.log(day)
        if (day.cash - day.mavro < 0) {
          // console.log('warning')
        }
        response.total.cash += (day.cash - day.mavro)
        console.warn(day.cash + '-' + day.mavro + '=' + (day.cash - day.mavro))
        response.total.card += Number(day.card)
        response.total.tickets += Number(day.tickets)
        response.total.nbTickets += Number(day.nbTickets)
        response.total.checks += Number(day.checks)
        response.total.mavro += Number(day.mavro)
      }
      response.total.total = response.total.cash + response.total.card + response.total.tickets + response.total.checks
      // console.log('returned total : ', response)
      // console.log(weekData.at(0))
      this.actualWeeksData[Object.keys(weekData)[0]] = response
      this.getMerchCost(Object.keys(weekData)[0])
    },
    getMerchCost (frenchDate) {
      const sentBody = this.$genFD({
        req: 'registerController',
        action: 'getWeekCost',
        date: frenchDate
      })

      fetch(this.$baseUrl, {
        body: sentBody,
        method: 'post'
      })
        .then(res => res.json())
        .then(data => {
          if (data.status !== 201) {
            console.error('error in getMerchCost')
          } else {
            this.actualWeeksData[frenchDate].costs = data.merchCost ?? 0
          }
        })
        .catch(err => {
          this.$swal({
            icon: 'error',
            toast: true,
            timer: 3000,
            position: 'top-end',
            title: 'Les comptes n\'ont pas pu être enregistrés',
            html: 'Veuillez contacter votre administrateur (ton frère). Erreur : ' + err,
            showConfirmButton: false
          })
        })
    }
  },
  created () {
    this.getHistoryData()
    // this.dataStore.methods.getHistoryData((result) => {
    //   const isoDate = result.key.split('/')[2] + '-' + result.key.split('/')[1] + '-' + result.key.split('/')[0]
    //   return isoDate >= this.startWeek && isoDate <= this.endWeek
    // }, this.handleWeeks)
  },
  mounted () {
    setTimeout(() => {
      this.getWeeklyData()
    }, 500)
    setTimeout(() => {
      this.exportToPDF()
    }, 1000)
  }
}
</script>

<style scoped>
  .paper {
    width: 21cm;
    height: 29.7cm;
    border: 1px solid black;
  }
  .table-container {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
  }
  .table-container table {
    width: 20%;
  }
  table:not(.table-container table) {
    width: 90%;
    margin: auto;
    margin-top: 1rem;
  }
  h1 {
    text-align: center;
    margin-left: auto;
    margin-right: auto;
  }
</style>
